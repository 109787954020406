#About {
    display: flex;
    justify-content: center;
    background-color: #e0e0df;
    background-image: url("../../images/absurdity.png");
    /* height: 100vh; */
    padding-top: 100px;
    padding-bottom: 700px;
}

.about-container {
    display: flex;
    justify-content: space-between;
    height: 440px;
    width: 1200px;
}

.about-image {
    background-image: url("../../images/about_image.png");
    background-size: cover;
    width: 278%;
    height: 440px;
}

.about-text-container {
    display: flex;
    align-self: center;
    padding-left: 138px;
}

.about-title {
    font-family: 'PT Serif', serif;
    font-size: 56px;
    font-weight: 100;
    margin-bottom: 26px;
}

.about-text {
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 22px;
    text-align: justify;
}

.about-greetings-text {
    margin-top: 20px;
}

.about-author-name, .about-greetings-text {
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 22px;
    text-align: right;
}

@media (max-width: 1200px) and (min-width: 851px) {
    .about-container {
        /* height: 350px; */
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .about-image {
        width: 290%;
        /* height: 350px; */
    }

    .about-text-container {
        padding-left: 60px;
    }
}

@media (max-width: 850px) {
    #About {
        padding-top: 50px;
    }

    .about-container {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    .about-text-container {
        padding-top: 10px;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .about-title {
        font-size: 34px;
        margin-bottom: 10px;
    }
}

@media (max-width: 850px) and (min-width: 550px) {
    .about-image {
        width: 95%;
        height: 340px;
        background-position-y: -80px;
        margin: 0 auto;
    }
}

@media (max-width: 549px) and (min-width: 426px){
    .about-image {
        width: 95%;
        height: 256px;
        background-position-y: -80px;
        margin: 0 auto;
    }
}

@media (min-width: 450px) {
    #About {
        height: 100vh;
    }
}

@media (max-width: 425px) {
    .about-image {
        width: 95%;
        height: 256px;
        background-position-y: -22px;
        margin: 0 auto;
    }
}

@media (max-width: 349px) {
    .about-image {
        height: 205px;
    }

    .about-title {
        font-size: 30px;
    }
}