.hyperbaric-chamber-benefit {
    .hyperbaric-chamber-benefit-section {
        height: auto;
        padding-top: 100px;
        padding-bottom: 100px;
        @media (max-width: 850px) {
            padding-top: 50px;
        }
        .hyperbaric-chamber-benefit-width-container {
            max-width: 1260px;
            margin: 0 auto;
            padding: 0 43px;
            @media (max-width: 400px) {
                padding: 0 0;
                width: 90%;
            }
            .hyperbaric-chamber-benefit-icon {
                width: 140px;
                margin: 53px 20px 20px 0;
                float: left;
                @media (max-width: 850px) {
                    width: 115px;
                    margin: 53px 20px 10px 0;
                }
                @media (max-width: 650px) {
                    width: 100px;
                }
                @media (max-width: 550px) {
                    width: 94px;
                    margin: 53px 15px 15px 0;
                }
            }
            .hyperbaric-chamber-benefit-text-container {
                display: inline;
                h2 {
                    text-align: center;
                    margin-bottom: 15px;
                    @media (max-width: 450px) {
                        text-align: left;
                    }
                    span {
                        text-transform: uppercase;
                    }
                }
                p {
                    letter-spacing: 1px;
                    text-align: justify;
                    white-space: pre-wrap;
                }
            }
        }
    }

    .hyperbaric-chamber-footer-section {
        background-color: #fff;
    }
}

