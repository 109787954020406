#Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #27292c;
    color: #fff;
}

.footer-container span {
    color: cornflowerblue;
    cursor: pointer;
}

.footer-container span:hover {
    color: #ecdd62;
}

@media (max-width: 1199px) and (min-width: 851px) {
    #Footer {
        height: 90px;
    }
}

@media (max-width: 850px) {
    #Footer {
        font-size: 15px;
        height: 68px;
    }
}

@media (max-width: 349px) {
    #Footer {
        height: 55px
    }
}

