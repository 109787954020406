#BlogPost {
    background-color: #e0e0df;
    background-image: url("../../images/absurdity.png");
    letter-spacing: 2px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.blogpost-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.blogpost-container-left-column {
    width: 66%;
}

.blogpost-post-container {
    padding-bottom: 50px;
}

.blogpost-post-image {
    height: 390px;
    overflow: hidden;
}

.blogpost-post-image img {
    width: 100%;
}

.blogpost-post-title {
    font-family: 'PT Serif', serif;
    font-size: 32px;
    font-weight: 100;
    padding-bottom: 20px;
}

.blogpost-post-text {
    font-weight: 600;
    padding: 20px 0 20px 0;
    line-height: 24px;
}

.blogpost-post-text div {
    text-align: justify;
}

.blogpost-comments-container {
    word-break: break-word;
}

.blogpost-comments-quantity-container h5 {
    font-family: 'PT Serif', serif;
    font-size: 56px;
    font-weight: 100;
    text-align: center;
    padding-bottom: 35px;
}

/*  Poniżej znajdują się style do komentarzy postów */



.blogpost-comment-name-and-text-wrapper {
    padding-top: 15px;
}

.blogpost-comment-name {
    display: flex;
    margin-bottom: 25px;
}

.blogpost-comment-name img {
    height: 60px;
    margin-right: 7px;
}

.blogpost-comment-name p {
    text-transform: uppercase;
    font-weight: bold;
}

.blogpost-comment-text {
    margin-bottom: 25px;
    text-align: justify;
}

.blogpost-comment-date-and-button-wrapper, .blogpost-comment-date-wrapper {
    display: flex;
    justify-content: flex-end;
}

.blogpost-comment-date {
    padding: 15px;
}

.blogpost-add-comment-form-title h5 {
    display: flex;
    justify-content: center;
    font-family: 'PT Serif', serif;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.add-comment-form-button {
    background-color: #302e33;
    color: #e0e0df;
    text-transform: uppercase;
    width: 157px;
    padding: 8px 0;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
}

.add-comment-form-button:hover {
    background-color: #ecdd62;
    color: black;
}

.blogpost-comment-date-and-button-wrapper {
    border-bottom: 1px solid #cdcdcc;
    padding: 15px;
}

.blogpost-comments-answer-container {
    padding-left: 35px;
}

.blogpost-comments-answer-wrapper {
    border-bottom: 1px solid #cdcdcc;
}

.blogpost-add-comments-answer-form-container {
    font-size: 27px;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 10px;
}

.blogpost-add-comments-answer-form h5 {
    font-family: 'PT Serif', serif;
}

.add-comment-form input, .add-comment-form textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: bold;
    resize: vertical;
}






.blogpost-container-right-column {
    width: 32%;
}


@media (max-width: 1199px) {
    #BlogPost {
        padding-top: 50px;
    }

    .blogpost-container {
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .blogpost-post-container {
        padding-bottom: 20px;
    }

    .blogpost-post-image {
        height: auto;
    }

    .blogpost-comment-name {
        margin-bottom: 5px;
    }

    .blogpost-comment-name img {
        height: 27px;
    }

    .blogpost-comment-text {
        margin-bottom: 15px;
    }

    .blogpost-comment-date-and-button-wrapper {
        flex-direction: column;
        padding: 0 0 15px 0;
    }

    .blogpost-comment-date {
        padding: 0;
        margin-bottom: 10px;
        align-self: flex-end;
    }

    .blogpost-add-comment-form-title h5 {
        font-size: 22px;
        font-family: 'PT Serif', serif;
        text-align: center;
    }

    .add-comment-form input, .add-comment-form textarea {
        padding: 6px;
        margin-bottom: 2px;
    }

    .blogpost-add-comments-answer-form h5 {
        font-size: 22px;
        text-align: center;
    }

    .blogpost-add-comment-form-title .add-comment-form-button {
        margin-top: 12px;
    }
}

@media (max-width: 850px) {
    .blogpost-container {
        width: 100%;
    }

    .blogpost-container-left-column {
        width: 100%;
    }

    .blogpost-container-right-column {
        display: none;
    }
}

@media (max-width: 649px) {
    .blogpost-post-title {
        font-size: 25px;
    }

    .blogpost-comments-quantity-container h5 {
        font-size: 25px;
        padding-bottom: 15px;
    }

    .blogpost-comment-button {
        width: 118px;
        padding: 4px 0;
        font-size: 16px;
        align-self: flex-end;
    }

    .add-comment-form-button {
        width: 118px;
        margin-top: 10px;
        padding: 4px 0;
        font-size: 16px;

    }
}

@media (max-width: 1199px) and (min-width: 851px) {
    .blogpost-post-image {
        height: 359px;
    }
}

@media (max-width: 1199px) and (min-width: 650px) {
    .blogpost-post-title {
        font-size: 30px;
    }

    .blogpost-comments-quantity-container h5 {
        font-size: 30px;
        padding-bottom: 15px;
    }

    .blogpost-comment-button{
        width: 136px;
        padding: 4px 0;
        font-size: 16px;
        align-self: flex-end;
    }

    .add-comment-form-button {
        width: 136px;
        margin-top: 10px;
        padding: 6px 0;
        font-size: 18px;
    }
}