#SecondHeader {
    display: flex;
    justify-content: center;
    font-weight: 100;
    letter-spacing: 2px;
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 24px;
    text-transform: uppercase;
    background-image: url('../../images/home_image.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    background-size: 100% 100%;
}

#SecondHeader ul {
    display: flex;
    justify-content: space-between;
}

#SecondHeader li {
    list-style-type: none;
    cursor: pointer;
    padding-left: 25px;
    margin-left: 25px; 
    border-left: 1px solid white;
}

#SecondHeader li:hover {
    color: #ecdd62;
}

@media (max-width: 1199px) and (min-width: 851px) {
    #SecondHeader {
        font-size: 20px;
        padding-top: 28px;
        padding-bottom: 28px;
    }

    #SecondHeader li {
        padding-left: 14px;
        margin-left: 14px;
        border-left: 1px solid white;
    }
}

@media (max-width: 850px) {
    #SecondHeader {
        font-size: 17px;
        padding-top: 28px;
        padding-bottom: 28px;
    }

    #SecondHeader li {
        padding-left: 12px;
        margin-left: 12px; 
        border-left: 1px solid white;
    }
}