#Blog {
    background-color: #e0e0df;
    background-image: url("../../images/absurdity.png");
    letter-spacing: 2px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.blog-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.blog-container-left-column {
 width: 66%;
}

.blog-post-container {
    padding-bottom: 50px;
}

.blog-post-title {
    padding: 0 0 40px 10px;
}

.blog-post-title h3 {
    font-family: 'PT Serif', serif;
    font-size: 56px;
    font-weight: 100;
}

.blog-post-image {
    height: 390px;
    overflow: hidden;
}

.blog-post-image img {
    width: 100%;
}

.blog-post-text {
    font-weight: 600;
    padding: 20px 0 20px 0;
    text-align: justify;
}

.blog-post-text p {
    line-height: 22px;
    letter-spacing: 2px;
}

.blog-post-read-more p {
    background-color: #302e33;
    color: #e0e0df;
    text-transform: uppercase;
    width: 197px;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    cursor: pointer;
}

.blog-post-read-more p:hover {
    background-color: #ecdd62;
    color: black;
}

.blog-container-right-column {
    width: 32%;
}

#PostCategories ul {
    list-style-type: circle;
}

.right-column-box {
    background-color: #28262b;
    color: white;
    margin-bottom: 65px;
    padding: 20px 37px;
}

.right-column-box li {
    padding-bottom: 10px;
    cursor: pointer;
}

.right-column-box li:hover {
    color: #ecdd62;
}

.right-column-box-title {
    display: flex;
    justify-content: center;
}

.right-column-box-title h3 {
    font-size: 20px;
    font-weight: 100;
    padding-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.recent-comments-user-name-and-date {
    padding-bottom: 7px;
}

@media (max-width: 1199px) {
    #Blog {
        padding-top: 50px;
    }

    .blog-container {
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .blog-post-container {
        padding-bottom: 35px;
    }

    .blog-post-title {
        padding: 0 0 12px 10px;
    }
}

@media (max-width: 850px) {
    .blog-container {
        width: 100%;
    }

    .blog-container-left-column {
        width: 100%;
    }

    .blog-container-right-column {
        display: none;
    }
}

@media (max-width: 649px) {
    .blog-post-title h3 {
        font-size: 25px;
    }

    .blog-post-image {
        height: auto;
    }

    .blog-post-read-more p {
        width: 90px;
        padding: 4px 0;
        font-size: 16px;
    }
}

@media (max-width: 1199px) and (min-width: 650px) {
    .blog-post-title h3 {
        font-size: 30px;
    }

    .blog-post-read-more p {
        width: 100px;
        padding: 6px 0;
        font-size: 18px;
    }
}

@media (max-width: 1199px) and (min-width: 851px) {
    .blog-post-image {
        height: 359px;
    }

    .right-column-box {
        margin-bottom: 30px;
        padding: 15px 37px;
    }
}

@media (max-width: 850px) and (min-width: 650px) {
   .blog-post-image {
        height: 400px;
    }
}
