#Login {
    display: flex;
    justify-content: center;
    background-color: #e0e0df;
    background-image: url("../../images/absurdity.png");
    height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 2.5%;
    padding-right: 2.5%;
}

.login-container h5 {
    margin-bottom: 40px;
    text-align: center;
    font-family: 'PT Serif', serif;
    font-size: 46px;
    font-weight: 100;
}

.login-container p {
    margin-top: 15px !important;
    margin: 0 auto;
    background-color: #302e33;
    color: #e0e0df;
    text-transform: uppercase;
    width: 197px;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    cursor: pointer;
}

@media (max-width: 649px) {
    #Login {
        padding-top: 50px;
        padding-bottom: 440px;
    }

    .login-container h5 {
        font-size: 30px;
    }

    input {
        padding: 6px;
        margin-bottom: 2px;
    }

    #Login p {
        width: 90px;
        padding: 4px 0;
        margin-top: 10px;
        font-size: 16px;
    }
}