@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,700;1,500;1,600;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/league-spartan');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,800;1,800&display=swap');

$font-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

$font-roboto: 'Roboto', sans-serif;

$font-montserrat:  'Montserrat', sans-serif;

$font-league-spartan: 'League Spartan', sans-serif;

$font-barlow: 'Barlow', sans-serif;


