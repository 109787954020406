.hyperbaric-chamber-contact-section {
    height: auto;
    padding-top: 65px;
    background-color: #e4e4e4;
    position: relative;
    overflow: hidden;
    .hyperbaric-chamber-contact-send-email-success {
        font-family: $font-montserrat;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .hyperbaric-chamber-contact-width-container {
        display: flex;
        justify-content: space-between;
        max-width: 1260px;
        margin: 0 auto;
        padding: 0 43px;
        @media (max-width: 1260px) {
            width: 100%;
        }
        @media (max-width: 850px) {
            flex-direction: column;
        }
        @media (max-width: 450px) {
            padding: 0 0;
        }
        .hyperbaric-chamber-map-and-address-container {
            width: 48%;
            @media (max-width: 850px) {
                align-self: center;
                width: 100%;
                margin-bottom: 60px;
            }
            @media (max-width: 450px) {
                width: 90%;
             }
            .hyperbaric-chamber-map-title-container {
                margin-bottom: 35px;
                h2 {
                    font-family: $font-montserrat;
                    font-size: 30px;
                    text-transform: uppercase;
                    color: black;
                    @media (max-width: 450px) {
                        font-size: 27px;
                    }
                }
            }
            .hyperbaric-chamber-map-container {
                height: 190px;
                iframe {
                    width: 100%;
                    height: 100%;
                    border: 0px;
                    display: block;
                    position: relative;
                }
            }
            .hyperbaric-chamber-address-container {
                font-family: $font-montserrat;
                padding-top: 30px;
                color: black;
                h3 {
                    margin-bottom: 10px;
                }
                .hyperbaric-chamber-address-phone {
                    margin-top: 8px;;
                }
                .hyperbaric-chamber-address-nip {
                    margin-top: 8px;;
                }
            }
            .hyperbaric-chamber-address-working-hours {
                font-family: $font-montserrat;
                padding-top: 20px;
                color: black;
                h3 {
                    margin-bottom: 10px;
                }
            }
        }
        .hyperbaric-chamber-contact-form-container {
            width: 48%;
            font-family: $font-montserrat;
            @media (max-width: 850px) {
                align-self: center;
                width: 100%;
            }
            @media (max-width: 450px) {
                width: 90%;
             }
            .hyperbaric-chamber-contact-form-title-container {
                margin-bottom: 35px;
                color: black;
                h2 {
                    font-family: $font-montserrat;
                    font-size: 30px;
                    text-transform: uppercase;
                    @media (max-width: 450px) {
                        font-size: 27px;
                    }
                }
            }
            .hyperbaric-chamber-contact-form-box {
                form {
                    input, textarea {
                        display: block;
                        width: 100%;
                        height: 46px;
                        margin-bottom: 20px;
                        border: 1px solid #fff;
                        border-radius: 0px;
                        font-family: $font-montserrat;
                        font-size: 16px;
                        color: black;
                        outline: none;
                    }
                    input {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    textarea {
                        height: 200px;
                        padding: 3px 10px;
                    }
                    button {
                        border: 1px solid black;
                        border-radius: 0px;
                        padding: 13px 37px;
                        font-family: $font-montserrat;
                        font-size: 15px;
                        background-color: #fefefe;
                        border: 1px solid #fefefe;
                        cursor: pointer;
                    }
                    ::placeholder {
                        color: black;
                    }
                }
            }
        }
    }
}