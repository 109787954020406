#Home {
    color: #fff;
    font-family: 'League Gothic', sans-serif;
}

.home-container {
    display: flex;
    justify-content: center;
    padding-top: 216px;
    padding-bottom: 500px;
    height: 100vh;
    background-image: url("../../images/home_image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
}

.home-text-title {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 8px solid white;
    border-bottom: 8px solid white;
    text-transform: uppercase;
    width: 1000px;
    text-align: center;
    font-size: 44px;
    font-weight: 100;
    letter-spacing: 33px;
}

.home-text-undertitle {
    text-align: center;
    margin-top: 48px;
    font-size: 30px;
    letter-spacing: 11px;
    font-weight: 100;
}

@media (max-width: 1250px) and (min-width: 849px) {
    .home-text-title {
        width: 800px;
    }
}

@media (max-width: 850px) {
    .home-container {
        padding-top: 140px;
    }

    .home-text-container {
        width: 95%;
    }
}

@media (max-width: 850px) and (min-width: 550px) {
    .home-text-title {
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 4px solid white;
        border-bottom: 4px solid white;
        font-size: 26px;
        letter-spacing: 23px;
        width: 90%;
        margin: 0 auto;
    }

    .home-text-undertitle {
        text-align: center;
        margin-top: 42px;
        font-size: 20px;
        letter-spacing: 4px;
    }
}

@media (max-width: 549px) {
    .home-text-title {
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 4px solid white;
        border-bottom: 4px solid white;
        font-size: 24px;
        letter-spacing: 23px;
        width: 90%;
        margin: 0 auto;
    }

    .home-text-undertitle {
        text-align: center;
        margin-top: 42px;
        font-size: 18px;
        letter-spacing: 4px;
    }
}

@media (max-width: 349px) {
    .home-text-title {
        padding-top: 10px;
        padding-bottom: 10px;
        letter-spacing: 17px;
    }

    .home-text-undertitle {
        margin-top: 20px;
        letter-spacing: 2px;
    }
}