@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

#Logo {
    background-color: #27292c;
}

.logo-container {
    /*
    width: 20%;
    */
    margin: 0 auto;
    border-bottom: 1px solid #3f3e41;
}

#Logo h1 {
    font-family: 'Courgette', cursive;
    font-size: 38px;
    font-weight: 100;
    letter-spacing: 2px;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    cursor: pointer;
}

#Navigation {
    font-family: 'Source Sans Pro', sans-serif;
    background-color: #27292c;
    padding-top: 45px;
    padding-bottom: 45px;
}

#nav-container {
    /*
    width: 1200px;
    */
    margin: 0 auto;
}

#nav-container ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    width: 550px;
    margin: 0 auto;
}

#nav-container li {
    padding: 15px 22px;
    color: #fff;
    letter-spacing: 2px;
    cursor: pointer;
}

#nav-container li:hover {
    background-color: #ecdd62;
    color: black;
}

@media (max-width: 1199px) and (min-width: 851px) {
    #Logo h1 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #Navigation {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    #nav-container li {
        padding: 10px 22px;
    }
}

@media (max-width: 850px) {
    .logo-container {
        width: 70%;
    }

    #Logo h1 {
        font-size: 36px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    #Navigation {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

@media (max-width: 850px) and (min-width: 650px) {
    #nav-container ul {
        width: 80%;
    }

    #nav-container li {
        font-size: 15px;
        padding: 4px 6%; 
    }
}

@media (max-width: 649px) {
    #nav-container ul {
        width: 90%;
    }

    #nav-container li {
        font-size: 15px;
    }
}

@media (max-width: 649px) and (min-width: 550px) {
    #nav-container li {
        padding: 4px 6%; 
    }
}

@media (max-width: 549px) and (min-width: 450px) {
    #nav-container li {
        padding: 4px 5%;
    }
}

@media (max-width: 449px) {
    #nav-container ul {
        width: 90%;
    }

    #nav-container li {
        padding: 4px 1%;
    }
}

@media (max-width: 349px) {
    #Logo h1 {
        font-size: 32px;
    }

    #Navigation {
        padding-top: 16px;
        padding-bottom: 15px;
    }

    #nav-container ul {
        width: 76%;
    }

    #nav-container li {
        font-size: 14px;
    }
}