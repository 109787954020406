#Contact {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #e0e0df;
    background-image: url("../../images/absurdity.png");
    /*
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(221,139,58,1) 54%, rgba(4,30,41,1) 100%);
    */
    /*
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(127,63,0,1) 54%, rgba(4,30,41,1) 100%);
    */
}

.contact-container {
    max-width: 650px;
    margin: 0 auto;
    border-radius: 5px;
  }

.contact-title {
    padding-bottom: 40px;
}

.contact-title h5 {
    display: flex;
    justify-content: center;
    font-family: 'PT Serif', serif;
    font-size: 56px;
    font-weight: 100;
}
  
input, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: bold;
    resize: vertical;
  }

textarea {
    height: 185px;
}
  
#Contact p {
    background-color: #302e33;
    color: #e0e0df;
    text-transform: uppercase;
    width: 197px;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    cursor: pointer;
  }

#Contact p:hover {
    background-color: #ecdd62;
    color: black;
}

@media (max-width: 649px) {
    #Contact {
        padding-top: 50px;
        padding-bottom: 440px;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .contact-title h5 {
        font-size: 30px;
    }

    .contact-title {
        padding-bottom: 20px;
    }

    input, textarea {
        padding: 6px;
        margin-bottom: 2px;
    }

    #Contact p {
        width: 90px;
        padding: 4px 0;
        margin-top: 10px;
        font-size: 16px;
    }
}
  
  
