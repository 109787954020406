.hyperbaric-chamber-about-section {
    width: 100%; 
    height: auto;
    overflow: hidden;
    .hyperbaric-chamber-about-container {
        display: flex;
        justify-content: space-between;
        width: 1260px;
        margin: 0 auto;
        padding: 90px 43px;
        @media (max-width: 1260px) {
            width: 100%;
        }
        @media (max-width: 850px) {
            display: block;
            width: 90%;
            margin: 0 auto;
            padding: 90px 0;
        }
        .hyperbaric-chamber-about-image {
            background-image: url("../../images/marzena-image.png");
            background-size: contain;
            height: 330px;
            width: 330px;
            background-repeat: no-repeat;
            @media (max-width: 1150px) {
                height: 330px;
                width: 330px;
            }
            @media (max-width: 1050px) {
                height: 313px;
                width: 313px;
            }
            @media (max-width: 950px) {
                height: 313px;
                width: 313px;
            }
            @media (max-width: 850px) {
                height: 345px;
                width: 345px;
                margin: 0 auto;
            }
            @media (max-width: 500px) {
                height: 252px;
                width: 252px;
            }
        }
        .hyperbaric-chamber-about-text {
            width: 750px;
            text-align: center;
            @media (max-width: 1260px) {
                width: 50%;
            }
            @media (max-width: 1150px) {
                width: 50%;
            }
            @media (max-width: 850px) {
                width: 100%;
                margin-top: 35px;
            }
            @media (max-width: 450px) {
                width: 100%;
                margin-top: 16px;
            }
            .hyperbaric-chamber-about-text-title {
                color: black;
                margin-bottom: 38px;
                font-family: $font-montserrat;
                line-height: 35px;
                letter-spacing: 2px;
                font-size: 40px;
                text-transform: uppercase;
                @media (max-width: 1150px) {
                    font-size: 40px;
                }
                @media (max-width: 950px) {
                    font-size: 38px;
                }
                @media (max-width: 850px) {
                    font-size: 32px;
                }
            }
            .hyperbaric-chamber-about-text-text {
                color: black;
                font-family: $font-montserrat;
                line-height: 28px;
                letter-spacing: 2px;
                font-size: 16px;
                @media (max-width: 1150px) {
                    font-size: 16px;
                    line-height: 28px;
                }
                @media (max-width: 1050px) {
                    font-size: 16px;
                    line-height: 25px;
                }
                @media (max-width: 950px) {
                    font-size: 16px;
                    line-height: 25px;
                }
                @media (max-width: 850px) {
                    font-size: 16px;
                    line-height: 23px;
                }
            }
        }
    }
}