.hyperbaric-chamber-price-list-section {
  height: auto;
  background-color: #181842;
  padding-top: 90px;
  padding-bottom: 140px;
  overflow: hidden;
  @media (max-width: 650px) {
    padding-bottom: 95px;
  }
  .hyperbaric-chamber-price-list-width-container {
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 43px;
    @media (max-width: 450px) {
      padding: 0 0;
    }
    .hyperbaric-chamber-price-list-title {
      display: flex;
      justify-content: center;
      font-family: $font-montserrat;
      text-transform: uppercase;
      line-height: 70px;
      letter-spacing: 3px;
      font-weight: 100;
      font-size: 28px;
      color: white;
      margin-bottom: 35px;
      @media (max-width: 850px) {
        font-size: 25px;
      }
    }
    .hyperbaric-chamber-price-list-boxes-container {
      display: flex;
      justify-content: space-between;
      height: 250px;
      @media (max-width: 650px) {
        display: flex;
        flex-direction: column;
        height: auto;
      }
      .hyperbaric-chamber-price-box {
        background-color: white;
        border: 3px solid #fd9800;
        width: 27%;
        padding-top: 25px;
        color: black;
        @media (max-width: 850px) {
          width: 30%;
        }
        @media (max-width: 750px) {
          width: 31%;
        }
        @media (max-width: 650px) {
          width: 60%;
          margin: 0 auto;
          margin-bottom: 45px;
        }
        @media (max-width: 550px) {
          width: 65%;
        }
        @media (max-width: 450px) {
          width: 75%;
        }
        @media (max-width: 400px) {
          width: 90%;
        }
        .hyperbaric-chamber-price-box-price-promotion {
          font-family: $font-montserrat;
          //   text-transform: uppercase;
          line-height: 36px;
          letter-spacing: 3px;
          font-weight: 100;
          font-size: 28px;
          color: black;
          //   border-bottom: 1px solid black;
          text-align: center;
          margin: 0 auto;
          //   margin-top: 20px;
          //   padding-bottom: 15px;
          text-transform: uppercase;
          //   width: 140px;
          @media (max-width: 800px) {
            font-size: 26px;
          }
          @media (max-width: 750px) {
            font-size: 23px;
          }
          @media (max-width: 650px) {
            font-size: 28px;
          }
        }
        .hyperbaric-chamber-price-box-price {
          font-family: $font-montserrat;
          text-transform: uppercase;
          line-height: 36px;
          letter-spacing: 3px;
          font-weight: 100;
          font-size: 28px;
          color: black;
          border-bottom: 1px solid black;
          text-align: center;
          margin: 0 auto;
          margin-top: 20px;
          padding-bottom: 15px;
          text-transform: lowercase;
          width: 140px;
          span {
            font-size: 18px;
          }
        }
        .hyperbaric-chamber-price-box-sessions-qty {
          font-family: $font-montserrat;
          font-style: italic;
          text-transform: uppercase;
          line-height: 70px;
          letter-spacing: 3px;
          font-weight: 100;
          font-size: 22px;
          font-style: italic;
          text-align: center;
          text-transform: lowercase;
        }
      }
    }
  }
}

.price-box-visible {
  display: block;
}

.price-box-invisible {
  display: none;
}
