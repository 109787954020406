.hyperbaric-chamber-header-section {
  background-color: #fff;
  height: 90px;
  margin: 0 auto;
  padding-left: 2em;
  padding-right: 2em;
  position: sticky;
  top: 0px;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  font-family: $font-roboto;
  background-color: #fff;
  z-index: 3;
}

.hyperbaric-chamber-header-width-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1260px;
  height: 100%;
  margin: 0 auto;
}

.hyperbaric-chamber-logo-container {
  background-image: url("../../images/logo.png");
  background-size: contain;
  height: 80%;
  width: 109px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.hyperbaric-chamber-menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  z-index: 2;
  margin: 0;
  padding: 0;
}

.hyperbaric-chamber-menu > li {
  margin: 0 1rem;
  margin-left: 3rem;
  margin-right: 0px;
  overflow: hidden;
}

.hyperbaric-chamber-menu label {
  cursor: pointer;
  letter-spacing: 0.1rem;
}

.hyperbaric-chamber-menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#hyperbaric-chamber-menu-toggle {
  display: none;
}

.hyperbaric-chamber-menu-button,
.hyperbaric-chamber-menu-button::before,
.hyperbaric-chamber-menu-button::after {
  display: block;
  background-color: #000000;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.hyperbaric-chamber-menu-button::before {
  content: '';
  margin-top: -8px;
}

.hyperbaric-chamber-menu-button::after {
  content: '';
  margin-top: 8px;
}

#hyperbaric-chamber-menu-toggle:checked + .hyperbaric-chamber-menu-button-container .hyperbaric-chamber-menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#hyperbaric-chamber-menu-toggle:checked + .hyperbaric-chamber-menu-button-container .hyperbaric-chamber-menu-button {
  background: rgba(255, 255, 255, 0);
}

#hyperbaric-chamber-menu-toggle:checked + .hyperbaric-chamber-menu-button-container .hyperbaric-chamber-menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 1200px) {
  .hyperbaric-chamber-menu > li {
      margin-left: 2rem;
    }
}

@media (max-width: 1000px) {
  .hyperbaric-chamber-menu > li {
      margin-left: 1rem;
    }
}

@media (max-width: 850px) {
  .hyperbaric-chamber-menu-button-container {
    display: flex;
  }
  .hyperbaric-chamber-menu {
    position: absolute;
    top: 39px;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .hyperbaric-chamber-menu label {
    width: 100%;
    text-align: center;
  }
  #hyperbaric-chamber-menu-toggle ~ .hyperbaric-chamber-menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    cursor: pointer;
  }
  #hyperbaric-chamber-menu-toggle:checked ~ .hyperbaric-chamber-menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .hyperbaric-chamber-menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .hyperbaric-chamber-menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
  .hyperbaric-chamber-menu-button,
.hyperbaric-chamber-menu-button::before,
.hyperbaric-chamber-menu-button::after {
  height: 7px;
  width: 50px;
}

.hyperbaric-chamber-menu-button::before {
  margin-top: -13px;
}

.hyperbaric-chamber-menu-button::after {
  margin-top: 13px;
}
}  

@media (max-width: 550px) {
  .hyperbaric-chamber-header-section {
      height: 50px;
  }
  .hyperbaric-chamber-menu {
    top: 0px;
  }
  .hyperbaric-chamber-menu-button,
  .hyperbaric-chamber-menu-button::before,
  .hyperbaric-chamber-menu-button::after {
    height: 4px;
    width: 30px;
  }
  
  .hyperbaric-chamber-menu-button::before {
    margin-top: -8px;
  }
  
  .hyperbaric-chamber-menu-button::after {
    margin-top: 8px;
  }
}

.hyperbaric-chamber-header-section.hide {
  top: -100px;
}