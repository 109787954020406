.hyperbaric-chamber-slider {
    height: 100vh;
    overflow: hidden;
    background-color: #181842;
    .hyperbaric-chamber-slider-prev-and-next-buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 98%;
        margin-left: 10px;
        margin-right: 10px;
        position: absolute;
        z-index: 1;
        .hyperbaric-chamber-slider-prev-button {
            background-image: url("../../images/prev-button.png");
            background-size: contain;
            height: 50px;
            width: 30px;
            background-repeat: no-repeat;
            cursor: pointer;
        }
        .hyperbaric-chamber-slider-next-button {
            background-image: url("../../images/next-button.png");
            background-size: contain;
            height: 50px;
            width: 30px;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }   
    .hyperbaric-chamber-slide {
        display: flex;
        justify-content: flex-end;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        background-position-x: 147px;
        @media (max-width: 1260px) {
            background-position-x: -97px;
        }
        @media (max-width: 750px) {
            background-position-x: -360px
        }
        @media (max-width: 650px) {
            background-position-x: -360px
        }
        @media (max-width: 450px) {
            background-position-x: -437px
        }
        .hyperbaric-chamber-slider-info {
            display: flex;
            flex-direction: column;
            width: 1280px;
            padding-top: 89px;
            padding-left: 2%;
            margin: 0 auto;
            @media (max-width: 1450px) {
                padding-left: 10%;
            }
            @media (max-width: 1350px) {
                padding-left: 13%;
            }
            @media (max-width: 750px) {
                overflow: auto;
                padding-left: 0;
            }
            @media (max-width: 650px) {
                overflow: auto;
                padding-left: 0;
            }
            .hyperbaric-chamber-slider-info-komora {
                font-family: $font-montserrat;
                line-height: 35px;
                letter-spacing: 2px;
                font-size: 86px;
                text-transform: uppercase;
                color: #fff;
                font-weight: 100;
                @media (max-width: 1260px) {
                    font-size: 80px;
                }
                @media (max-width: 1150px) {
                    font-size: 70px;
                }
                @media (max-width: 750px) {
                    font-size: 60px;
                    text-align: center;
                }
                @media (max-width: 650px) {
                    font-size: 56px;
                }
                @media (max-width: 550px) {
                    font-size: 54px;
                }
                @media (max-width: 400px) {
                    font-size: 48px;
                }
            }
            .hyperbaric-chamber-slider-info-hiperbaryczna {
                font-family: $font-league-spartan;
                margin-top: 64px;
                line-height: 35px;
                letter-spacing: 2px;
                font-size: 67px;
                text-transform: uppercase;
                color: #fd9800;
                font-weight: 100;
                @media (max-width: 1250px) {
                    font-size: 63px;
                }
                @media (max-width: 1150px) {
                    font-size: 53px;
                }
                @media (max-width: 750px) {
                    font-size: 43px;
                    text-align: center;
                    margin-top: 46px;
                }
                @media (max-width: 650px) {
                    font-size: 39px;
                    text-align: center;
                    margin-top: 42px;
                }
                @media (max-width: 550px) {
                    font-size: 37px;
                    text-align: center;
                    margin-top: 42px;
                }
                @media (max-width: 400px) {
                    font-size: 31px;
                }
            }
            .hyperbaric-chamber-slider-info-jelenia-gora {
                font-family: $font-league-spartan;
                margin-top: 57px;
                line-height: 35px;
                font-size: 67px;
                text-transform: uppercase;
                color: #fff;
                font-weight: 100;
                @media (max-width: 1250px) {
                    font-size: 63px;
                }
                @media (max-width: 1150px) {
                    font-size: 53px;
                }
                @media (max-width: 750px) {
                    font-size: 43px;
                    text-align: center;
                    margin-top: 46px;
                }
                @media (max-width: 650px) {
                    font-size: 39px;
                    text-align: center;
                    margin-top: 42px;
                }
                @media (max-width: 550px) {
                    font-size: 37px;
                }
                @media (max-width: 400px) {
                    font-size: 31px;
                }
            }
            .hyperbaric-chamber-slider-info-terapia-tlenowa {
                font-family: $font-montserrat;
                margin-top: 65px;
                line-height: 70px;
                letter-spacing: 3px;
                font-weight: 100;
                font-size: 58px;
                font-style: italic;
                color: #fff;
                @media (max-width: 1250px) {
                    font-size: 54px;
                }
                @media (max-width: 1150px) {
                    font-size: 44px;
                }
                @media (max-width: 750px) {
                    font-size: 38px;
                    text-align: center;
                }
                @media (max-width: 650px) {
                    font-size: 34px;
                }
                @media (max-width: 550px) {
                    font-size: 32px;
                }
                @media (max-width: 400px) {
                    font-size: 30px;
                    margin-top: 45px;
                }
            }
            .hyperbaric-chamber-slider-info-zarezerwuj-wizyte {
                font-family: $font-montserrat;
                width: max-content;
                margin-top: 73px;
                padding-left: 23px;
                padding-right: 23px;
                line-height: 70px;
                letter-spacing: 1px;
                font-weight: 100;
                font-size: 31px;
                color: black;
                border: 1px solid #fd9800;
                border-radius: 38px;
                background-color: #fd9800 ;
                @media (max-width: 1250px) {
                    margin-top: 55px;
                    padding-left: 18px;
                    padding-right: 18px;
                    line-height: 60px;
                    font-size: 26px;
                }
                @media (max-width: 1150px) {
                    margin-top: 90px;
                }
                @media (max-width: 750px) {
                    margin: 0 auto;
                    margin-top: 90px;
                    line-height: 50px;
                }
                @media (max-width: 400px) {
                    font-size: 22px;
                    margin-top: 65px;
                }
            }
            .hyperbaric-chamber-slider-info-phone-number {
                display: flex;
                justify-content: space-between;
                width: 450px;
                padding-top: 58px;
                @media (max-width: 750px) {
                    display: block;
                    width: 100%;
                }
                @media (max-width: 650px) {
                    display: block;
                    width: 100%;
                }
                .hyperbaric-chamber-slider-info-phone-number-icon {
                    background-image: url("../../images/phone-icon.png");
                    background-size: contain;
                    height: 80px;
                    width: 106px;
                    background-repeat: no-repeat;
                    @media (max-width: 1150px) {
                        width: 90px;
                    }
                    @media (max-width: 750px) {
                        margin: 0 auto;
                        width: 66px;
                    }
                    @media (max-width: 650px) {
                        margin: 0 auto;
                        width: 66px;
                    }
                }
                .hyperbaric-chamber-slider-info-phone-number-text {
                    width: 68%;
                    @media (max-width: 750px) {
                        margin: 0 auto;
                    }
                    @media (max-width: 650px) {
                        margin: 0 auto;
                    }
                    .hyperbaric-chamber-slider-info-phone-number-name {
                        color: #fff;
                        font-family: $font-barlow;
                        font-size: 36px;
                        font-weight: 300;
                        @media (max-width: 1150px) {
                            font-size: 32px;
                        }
                        @media (max-width: 750px) {
                            display: none;
                        }
                        @media (max-width: 650px) {
                            display: none;
                        }
                    }
                    .hyperbaric-chamber-slider-info-phone-number-number {
                        color: #fff;
                        font-family: $font-barlow;
                        font-size: 45px;
                        letter-spacing: 2px;
                        @media (max-width: 1150px) {
                            font-size: 40px;
                        }
                        @media (max-width: 750px) {
                            font-size: 40px;
                            text-align: center;
                        }
                        @media (max-width: 650px) {
                            font-size: 36px;
                            text-align: center;
                        }
                        @media (max-width: 550px) {
                            font-size: 34px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }     
}